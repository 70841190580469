import React, { useState, useEffect, useRef } from "react"
import AliceCarousel from "react-alice-carousel"

import ProductCard from "./ProductCard"
import { useProductos } from "../../../hooks/useProductos"
import "./ProductList.scss"

export default function ProductList({ width, product }) {
  const { productos, getCategoria } = useProductos();
  const percent = 0.25;
  const section = useRef(null);
  const [padding, setPadding] = useState(0);
  const syncState = () => {
    const { current } = section
    if (current) {
      setPadding(current.offsetWidth * percent)
    }
  };
  const [products, setProducts] = useState([]);

  useEffect(syncState, []);

  useEffect(() => {
    if (productos && productos.length > 0 && product) {
      let {products} = productos[getCategoria(product.variant_id)];
      setProducts([...products, ...productos[3].products].slice(0, 4));
    }
  }, [productos, product]);

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    700: { items: 3 },
  }

  return (
    <div className="productos-relacionados">
      <h4 className="title">TAMBIÉN TE PUEDE INTERESAR</h4>
      {width >= 768 ? (
        <div className="row px-3 mx-auto my-5 lista">
          {products && products.map(item =>
            <div className="col-3" key={item.variant_id}>
              <ProductCard producto={item} />
            </div>
          )}
        </div>
      ) : (
        <div ref={section} className="productos-relacionados_carousel  ">
          {products && products.length > 0 && (
            <AliceCarousel
              mouseTracking
              animationType="slide"
              autoPlayInterval="3000"
              infinite
              disableDotsControls
              autoPlay
              paddingLeft={padding}
              paddingRight={padding}
              onResized={syncState}
              responsive={responsive}
              items={products.map(item => <ProductCard key={item.variant_id} producto={item} />)}
            />
          )}
        </div>
      )}
    </div>
  );
}
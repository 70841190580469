import React from "react"
import SEO from "../../components/seo"

import NavbarStore from "../../components/Store/NavbarStore"
import AllProducts from "../../components/Store/Products/AllProducts"
import PreFooter from "../../components/Store/PreFooter"
import { FooterStore } from "../../components/Store/FooterStore"
import FooterSmall from "../../components/FooterSunset"

import useWindowDimensions from "../../hooks/useWindowDimensions"
import ProductList from "../../components/Store/Products/ProductList"
import loadable from '@loadable/component'
const ProductDetail = loadable(() => import('../../components/Store/Products/ProductDetail'))
// import ProductDetail from "../../components/Store/Products/ProductDetail"

function DescripcionProducto() {
  const { width } = useWindowDimensions()

  return (
    <div>
      <SEO title="Productos | Corona Store" />
      <NavbarStore width={width} />
      <ProductDetail />
      {width <= 500 ? <FooterSmall styles={{ paddingTop: "150px" }} /> : <FooterStore />}
    </div>
  )
}

export default DescripcionProducto
